<template>
<div>
    <page-header :title="`Tải lên tệp excel mẫu`" :items="breadcrumbs" />
    <div class="card">
        <div class="card-body">
            <b-col lg="4">
                <label>Chọn tệp excel</label>
                <a-file class="my-pond" :value="form.value" @input="value => form.value = value"/>
                <b-button v-show="form.value" variant="success" @click="update">Lưu lại</b-button>
            </b-col>
        </div>
    </div>
</div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import Swal from 'sweetalert2';
import AFile from './partials/a-file';
import {
    httpClient
} from "../../../_utils/httpClient";

export default {
    name: 'list',
    components: {
        PageHeader,
        AFile
    },
    data: () => ({
        breadcrumbs: [{
            text: 'Trang chủ',
            to: '/admin/home',
        }, {
            text: 'Tải tệp excel mẫu',
            to: '#',
            active: true,
        }, ],
        form: {
            id: '',
            value: "",
            config: "templateuserfile",
        },
        entries: []
    }),
    methods: {
        async update() {
            await Swal.queue([{
                title: 'Tải tệp excel mẫu !',
                confirmButtonText: 'Lưu',
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                showconfirmButton: true,
                preConfirm: async () => {
                    this.showAddErrorMessage = false
                    let response = await httpClient.post(`config`, this.form)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Tải tệp excel mẫu thành công !'
                        })
                        this.remove_file()
                    } else {
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data
                    }
                    return true;
                }

            }, ]);
        },
        async create() {
            await Swal.queue([{
                title: 'Tải tệp excel mẫu !',
                confirmButtonText: 'Lưu',
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                showconfirmButton: true,
                preConfirm: async () => {
                    this.showAddErrorMessage = false
                    let response = await httpClient.post(`config`, this.form)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Tải tệp excel mẫu'
                        })
                        this.entries = response.data.data
                        this.$router.go(0);
                    } else {
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data
                    }
                    return true;
                }

            }, ]);
        },
        async getData() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'templateuserfile'
                }
            })
            for(let key in this.form) {
                if(key == 'value') continue;
                this.form[key] = response.data.data[0][key]
            }
        },
        remove_file() {
            document.querySelector('.filepond--file-action-button.filepond--action-revert-item-processing').click()
            this.form.value = ""
        }
    },
    async created() {
        await this.getData()
        document.querySelector('.my-pond').addEventListener('FilePond:removefile', () => {
            this.form.value = ""
        });
    },
};
</script>

<style scoped>
.style-width {
    min-width: 140px;
}

.style-link {
    color: #50a5f1;
    border: 1px solid #50a5f1;
    padding: 10px;
    border-radius: 4px;
}

.style-link:hover {
    background-color: #50a5f1;
    color: #fff;
}

</style>
